import React from 'react';

import Menu from './Menu';
import Footer from './Footer';

import '../css/Contact.css';


class Contact extends React.Component{
    render(){
        return (
            <div id="contact">
                <Menu />

                Salut c'est françis

                <Footer />            
            </div>
        );
    }
}

export default Contact;