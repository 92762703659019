import React from 'react';
import { useParams } from "react-router-dom";
import { CoverData } from '../Data/Data';
import { Helmet } from "react-helmet";

import '../css/Project.css';

function Project() {
    const { url } = useParams();
    const project = CoverData.find(e => e.url === url)

    return (
        <div id="project">
            <Helmet>
              <title>Pesto Rosso Records - {project.title}</title>
              <meta property="og:title" content={`${project.artist} - {project.title}`} />
              <meta property="og:description" content={project.description} />
            </Helmet>
            <div className="projectTitle"></div>
            <div className="projectHolder">
                <div className="projectCover">
                    <img src={require(`../img/${project.coverName}`)} alt={project.title} />
                    <div className="nameAndReleaseDate">
                        {project.title} <br />
                        {project.artist} <br />
                        Sortie : {project.releaseDate} <br /><br />
                        {project.bandcamp &&
                            <a rel="noopener noreferrer" target="_blank" href={project.bandcamp}>
                                Acheter sur Bandcamp
                            </a>
                        }<br />
                        {project.soundcloud &&
                            <a rel="noopener noreferrer" target="_blank" href={project.soundcloud}>
                                Ecouter sur Soundcloud
                            </a>
                        }<br />
                        {project.youtube &&
                            <a rel="noopener noreferrer" target="_blank" href={project.youtube}>
                                Ecouter sur Youtube
                            </a>
                        }
                    </div>
                </div>
                <div className="projectDescription">
                    <div className="projectDescriptionText">
                        {project.description}
                    </div>
                    <iframe title="embedIFrame" id="embedIFrame" src={project.embed} height="400" frameBorder="0" allowtransparency="true" allow="encrypted-media"></iframe>

                </div>
            </div>
        </div>
    );
}

export default Project;
