import React from 'react';
import { Route, Switch } from 'react-router-dom';

import Landing from '../Component/Landing';
import Project from '../Component/Project';
import Contact from '../Component/Contact';
import Mixes from '../Component/Mixes';

export const Routes =
    <Switch>
        <Route exact path='/' component={Landing} />
        <Route exact path='/Contact' component={Contact} />
        <Route exact path='/mixes' component={Mixes} />
        <Route path='/:url' component={Project} />
    </Switch>
