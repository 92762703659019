import React from 'react';

import '../css/Footer.css';


class Footer extends React.Component{
    render(){
        return (
            <div id="footer">
                © { new Date().getFullYear() } PESTO ROSSO RECORDS.
            </div>
        );
    }
}

export default Footer;
