import React from 'react';
import { Link } from 'react-router-dom';

import '../css/Menu.css';


class Menu extends React.Component{
    render(){
        return (
            <div id="menu">
                <div className="logoContainer">
                    <img src={require('../img/prrlogo.png')} alt="logo pesto rosso" width="150px"></img>
                </div>
                <div className="nav">
                    <div className="tabWrapper">
                        <div className="tab"><Link to={`/`}>RELEASES</Link></div>
                        <div className="tab"><Link to="/mixes">MIXES</Link></div>
                        {/*
                        <div className="tab">NEWS</div>
                        <div className="tab">CONTACT</div>*/}
                    </div>
                </div>
            </div>
        );
    }
}

export default Menu;
