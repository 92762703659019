import React, { useEffect, useState } from 'react';

import { Helmet } from "react-helmet";
import '../css/mixes.css';

const getUser = mix => mix.match(/.*\/(.*)\/(.*)\/$/)[1]
const getTitle = mix => mix.match(/.*\/(.*)\/(.*)\/$/)[2]

const Mixes = () => {
  const [data, dataSet] = useState(null);

  useEffect(() => {
    (async () => {
      let response = await fetch('https://api.mixcloud.com/PestoRossoRecords/favorites/');
      response = await response.json();
      const mixes = response.data
        ? response.data
            .sort((a, b) => new Date(b.created_time) - new Date(a.created_time))
            .map(elm => elm.url)
        : null;
      dataSet(mixes);
    })()
  }, []);

  return (
    <>
      <Helmet>
        <title>Pesto Rosso Records - Mixes</title>
      </Helmet>
      <div id="coverList">
      {data && data.map((mix, k) =>
        <div
          key={k}
          className="mix">
          <iframe
            title={`mix-${k}`}
            width="100%"
            height="385"
            src={`https://www.mixcloud.com/widget/iframe/?feed=%2F${getUser(mix)}%2F${getTitle(mix)}%2F`}
            frameBorder="0" />
        </div>
      )}
      </div>
    </>
  )
}

export default Mixes;
