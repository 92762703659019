import React from 'react';

import Menu from './Component/Menu';
import Footer from './Component/Footer';
import {Routes} from './Routes/Routes';

import './App.css';

function App() {
  return (
    <div id="App">
      <Menu />
      {Routes}
      <Footer />
    </div>
  );
}

export default App;
