import React from 'react';

import { Helmet } from "react-helmet";
import CoverList from './CoverList';

class Landing extends React.Component{
    render(){
        return (
            <div id="landing">
              <Helmet>
                <title>Pesto Rosso Records</title>
              </Helmet>
              <CoverList />
            </div>
        );
    }
}

export default Landing;
