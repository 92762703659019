import React from 'react';
import { Link } from 'react-router-dom';

import { CoverData } from '../Data/Data';

import '../css/CoverList.css';


class CoverList extends React.Component {
    render() {
        return (
            <div id="coverList">

                {CoverData.map((cover, index) => {

                    return (

                        <div className="cover" key={index}>
                            <Link to={`/${cover.url}`}>
                                <div className="coverTitleHolder"><span className="coverTitle">{cover.title}</span></div>

                                <img src={require(`../img/${cover.coverName}`)} alt={cover.title} />

                            </Link>
                        </div>

                    );

                }
                )}
            </div>
        );
    }
}

export default CoverList;
