export const CoverData =[
  {
    url: "oa-so-vw",
    title:"OA-SO-VW",
    artist: "woffdog",
    releaseDate: "01 février 2022",
    coverName: "oasovw.jpg",
    description: "Un plongeon dans l'énigmatique. En apnée dans l'hypnotisme, les tréfonds d'une vibration sismique. À vitesse lumière dans l'obscurité.",
    embed: "https://open.spotify.com/embed/album/7gCoTPC63HR7CRG3EvRdV2",
    bandcamp: "https://woffdog.bandcamp.com/album/oa-so-vw",
    soundcloud: "https://soundcloud.com/woffdog/sets/oa-so-vw"
  },
  {
    url: "closings",
    title:"Closings",
    artist: "Gray Umber Sky",
    releaseDate: "17 novembre 2021",
    coverName: "closings.jpg",
    description: "\"3 tracks très smooth et rythmées que j'imagine volontiers en fin de set pour terminer en beauté sur des notes célestes et poétiques.\"",
    embed: "https://open.spotify.com/embed/album/1RapMWbnX2VMhIthHhskcj",
    bandcamp: "https://grayumbersky.bandcamp.com/album/closings",
    youtube: "https://www.youtube.com/watch?v=4mZQ-U6GdoY&list=PLv0szsN7bgUEvLlP0JQM9eUR7LK4gQEZ7",
    soundcloud: "https://soundcloud.com/gray-umber-sky/sets/closings"
  },
  {
    url: "interference",
    title:"interférence",
    artist: "atomic boom",
    releaseDate: "26 février 2021",
    coverName: "interference.jpg",
    description: "Au départ d'une exploration aux confins de l'espace, un astronaute voit ses systèmes de communication s'interrompre petit à petit. Plus qu'une expédition spatiale, il va questionner l'univers et de fait, son identité. Il va alors alterner entre craintes et moments d'apaisement au fur et à mesure de sa découverte.",
    embed: "https://open.spotify.com/embed/album/1lKWwQSVyoGRM8X3ylxkCL",
    bandcamp: "https://atomicboom.bandcamp.com/album/interf-rence"
  },
  {
    url: "le-dernier-mirage",
    title:"Le Dernier Mirage",
    artist: "woffdog",
    releaseDate: "19 février 2021",
    coverName: "derniermirage.jpg",
    description: "La fin du voyage, le réveil, le dernier mirage.",
    embed: "https://open.spotify.com/embed/album/46PETcfA5FquitXnOLLVZj",
    bandcamp: "https://woffdog.bandcamp.com/album/le-dernier-mirage",
    soundcloud: "https://soundcloud.com/woffdog/sets/le-dernier-mirage",
    youtube: "https://www.youtube.com/watch?v=0qEviyUlnKg&list=OLAK5uy_kAH0akNLMSQeLPTHBHUdWAPLTPFpWKCcs"
  },
  {
    url: "cauchemar-3-temps",
    title:"Cauchemar 3 Temps",
    artist: "woffdog",
    releaseDate: "29 décembre 2020",
    coverName: "cauchemar3temps.png",
    description: "Randonnée au cœur des songes, triptyque ambient et hypnotique. Découvrez le premier EP de woffdog :",
    embed: "https://open.spotify.com/embed/album/7sHaWZAIFwVHo4NWUbXG0b",
    bandcamp: "https://woffdog.bandcamp.com/album/cauchemar-3-temps",
    soundcloud: "https://soundcloud.com/woffdog/sets/cauchemar-3-temps",
    youtube: "https://www.youtube.com/watch?v=qK7P2edxmQg"
  },
  {
    url: "mineral-extraction",
    title:"Mineral Extraction",
    artist: "Falcon X",
    releaseDate: "22 Mai 2020",
    coverName: "mineralExtraction.jpg",
    description: "Falcon X nous emmène au fin fond de la vallée de la mort pour déterrer la grosse caillasse.",
    embed: `https://www.youtube.com/embed/A0CokDra4SI`
  },
  {
    url: "a-l-ecoute-du-monde",
    title:"À l'écoute du monde",
    artist: "Gray Umber Sky",
    releaseDate: "6 Septembre 2019",
    coverName: "ecouteDuMonde.jpg",
    description: `Un album composé sans synthés, uniquement avec des samples recoltés tout au long de son parcours eurasiatique de 42 000km à travers une trentaine de pays.
À l'écoute du monde, premier album de Gray Umber Sky, vous fera goûter des saveurs inconnues du Moyen Orient jusqu'en Asie du Sud Est.`,
    embed: "https://open.spotify.com/embed/album/5T7TAfqpbdQQBdnvRh6SOq"
  },
  {
    url: "minerals",
    title: "Miner.Als",
    artist: "Gray Umber Sky",
    releaseDate: "13 Mai 2019",
    coverName: "minerals.jpg",
    description: "Finement émincée lors de nombreuses performances live, la techno atmosphérique & organique de Gray Umber Sky vous guidera dans de longues randonnées et vous portera aux plus hauts sommets",
    embed: "https://open.spotify.com/embed/album/6isIdbTXnzbRNzQc2RY000"
  },  
  {
    url: "remords",
    title: "Remords",
    artist: "Yamee",
    releaseDate: "22 Mars 2019",
    coverName: "remords.jpg",
    description: "Deuxième EP de Yamee : Remords",
    embed: "https://open.spotify.com/embed/album/1jISHGaKMBWgOU6Fh7hygn"
  },
  {
    url: "a-different-breed-of-party",
    title: "A Different Breed Of Party",
    artist: "Yamee",
    releaseDate: "21 Decembre 2018",
    coverName: "aDifferentBreed.png",
    description: "Premier EP de Yamee : A Different Breed of Party",
    embed: "https://open.spotify.com/embed/album/6vGImuGqEGai45v2VYHyOr"
  },    
  {
    url: "la-ligne-cosmique",
    title:"La Ligne Cosmique",
    artist: "Bpouille",
    releaseDate: "21 Septembre 2018",
    coverName: "ligneCosmique.jpg",
    description: "Bpouille vous téléphone au bout du fil de sa ligne cosmique dans son premier EP : La Ligne Cosmique.",
    embed: "https://open.spotify.com/embed/album/68CYMCndstT6pPjAyNdKbk"
  }    
]

